import { Typography } from 'components/atoms/Typography';
import { AlertInformer } from 'components/molecules/AlertInformer';

export const PaymentAlertInformer = () => {
	return (
		<AlertInformer
			content={
				<Typography fontSize={'1rem'} fontWeight={400} textAlign={'start'}>
					Переводите указанную сумму с
					<Typography fontSize={'1rem'} fontWeight={600} asSubstring>
						{' '}
						точностью до копеек.{' '}
					</Typography>
					Комментарий к платежу писать не нужно. Не допускается повторная оплата по ранее использованным реквизитам, есть риск потери
					денежных средств.
				</Typography>
			}
		/>
	);
};
