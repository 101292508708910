import { CSSProperties, ReactNode } from 'react';

export enum TYPE_COLORS {
	'black' = 'black',
	'purple' = 'purple',
	'red' = 'red',
}

export type TypeProps = {
	children: ReactNode;
	fontSize?: string;
	fontWeight?: number;
	color?: TYPE_COLORS;
	width?: CSSProperties['width'];
	margin?: CSSProperties['margin'];
	padding?: CSSProperties['padding'];
	textAlign?: CSSProperties['textAlign'];
	lineHeight?: CSSProperties['lineHeight'];
	asSubstring?: boolean;
};
