import { IInputProps, Input } from 'components/atoms/Input';

type InputMaskProps = {
	maskFormatter: (value: string) => string;
} & IInputProps;

export const InputMask = ({ type = 'text', disabled = false, onChange, maskFormatter, ...restProps }: InputMaskProps) => {
	const inputHandler: IInputProps['onChange'] = (value, event) => {
		const formattedValue = maskFormatter(value);
		onChange?.(formattedValue, event);
	};

	return <Input {...restProps} onChange={inputHandler} />;
};
