export const parsePhoneNumber = (phoneNumber: string) => {
	const numericString = phoneNumber.replace(/[ \-()]/g, '');
	const parsedNumber = Number(numericString);

	if (isNaN(parsedNumber)) {
		return 0;
	}

	return parsedNumber;
};
