import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	min-height: 1.6rem;
	width: 100%;
`;

export const backLinkStyles: CSSProperties = {
	position: 'absolute',
	left: 0,
	cursor: 'pointer',
};
