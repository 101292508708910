import { IInputProps } from 'components/atoms/Input/types';
import { Input } from 'components/atoms/Input/ui/Input';

export const InputNumber = ({ onChange, ...restProps }: IInputProps) => {
	const inputHandler: IInputProps['onChange'] = (value, event) => {
		// remove all non-numeric symbols
		let numericString = value.replace(/[^0-9.]/g, '');
		// remove all decimal points except the first one
		numericString = numericString.replace(/(\..*?)\./g, '$1');

		if (numericString === '.') {
			numericString = '0.';
		}

		onChange?.(numericString, event);
	};

	return <Input {...restProps} onChange={inputHandler} />;
};
