import { useState } from 'react';
import { FormWrapper } from 'components/molecules/FormWrapper/FormWrapper';
import { FormHeader } from 'components/molecules/FormHeader/ui/FormHeader';
import { ReactComponent as RecallIcon } from 'assets/icons/recall.svg';
import { Footer, HeaderContent, HeaderWrapper } from './ConfirmNumberForm.styles';
import { Typography } from 'components/atoms/Typography';
import { InputMask } from 'components/atoms/InputMask';
import { confirmationCode } from 'utils/confirmationCode/confirmationCode.mask';
import { Button } from 'components/atoms/Button';
import { TYPE_COLORS } from 'components/atoms/Typography/Typography.types';
import { useCountdown } from 'hooks/useCountdown';
import { formatSecondsCountdown } from 'utils/countdown/formatSecondsCountdown';

export const ConfirmNumberForm = () => {
	const [code, setCode] = useState('');

	const { isExpired, formattedCountdown, resetTimer } = useCountdown({ seconds: 30, convertToCountdown: formatSecondsCountdown });

	const backLink = () => {};

	const requestCallHandler = () => {
		resetTimer();
	};

	return (
		<FormWrapper>
			<HeaderWrapper>
				<FormHeader backLinkAction={backLink} />

				<RecallIcon />
			</HeaderWrapper>

			<HeaderContent>
				<Typography fontSize={'1.125rem'} fontWeight={600}>
					Вам поступит звонок-сброс
				</Typography>
				<Typography width={'18.75rem'}>Введите последние 4 цифры номера, с которого поступит звонок</Typography>
			</HeaderContent>

			<InputMask value={code} onChange={setCode} maskFormatter={confirmationCode} label='Введите 4 цифры' placeholder='0000' />

			<Footer>
				<Button text={'Продолжить'} />

				{!isExpired && (
					<Typography color={TYPE_COLORS.purple} fontWeight={600}>
						Звонок поступит в течении {formattedCountdown}
					</Typography>
				)}

				{isExpired && <Button onClick={requestCallHandler} text={'Отправить повторно'} variant={'secondary'} />}
			</Footer>
		</FormWrapper>
	);
};
