import { PaymentErrorForm } from 'components/organisms/PaymentErrorForm';
import { BaseLayout } from 'components/templates/BaseLayout';

export const PaymentErrorPage = () => {
	return (
		<BaseLayout>
			<PaymentErrorForm />
		</BaseLayout>
	);
};
