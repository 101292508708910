type CopyToClipboardParams = {
	value: string;
	onSuccess?: () => void;
	onError?: () => void;
};

export const copyToClipboard = ({ value }: CopyToClipboardParams) => {
	try {
		navigator.clipboard.writeText(value);
	} catch (err) {
		console.error('Failed to copy: ', err);
	}
};
