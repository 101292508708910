import { useCountdown } from 'hooks/useCountdown';
import { formatSecondsCountdown } from 'utils/countdown/formatSecondsCountdown';
import { Header, ProgressBar, ProgressLine, Wrapper } from './CountdownBar.styles';
import { Typography } from 'components/atoms/Typography';

type CountdownBarProps = {
	label: string;
	timeoutSeconds: number;
};

export const CountdownBar = ({ label, timeoutSeconds }: CountdownBarProps) => {
	const { secondsLeft, formattedCountdown } = useCountdown({
		seconds: timeoutSeconds,
		convertToCountdown: formatSecondsCountdown,
	});

	const progressPercent = (secondsLeft * 100) / timeoutSeconds;

	return (
		<Wrapper>
			<Header>
				<Typography fontSize={'1.125rem'} fontWeight={600}>
					{label}
				</Typography>

				<Typography fontSize={'1.125rem'} fontWeight={600}>
					{formattedCountdown}
				</Typography>
			</Header>

			<ProgressBar>
				<ProgressLine $percent={progressPercent} />
			</ProgressBar>
		</Wrapper>
	);
};
