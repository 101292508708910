import { InfoField } from 'components/atoms/InfoField';
import { Stack } from 'components/atoms/Stack';
import { SberPayQr } from 'components/molecules/SberPayQr';

export const SberPayFields = () => {
	const mockPaymentAmount = 3333.5;
	const mockPaymentId = '4990ac66-2fca-4988-8361-949778d';

	return (
		<Stack flexDirection={'column'} gap={'1.25rem'} width={'100%'}>
			<InfoField leftLabel={'Номер платежа'} rightLabel={'19.05.2024 13:26:37'} value={mockPaymentId} copyable />

			<SberPayQr value={mockPaymentId} paymentAmount={mockPaymentAmount} />
		</Stack>
	);
};
