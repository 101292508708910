import { FormHeader } from 'components/molecules/FormHeader/ui/FormHeader';
import { FormWrapper } from 'components/molecules/FormWrapper/FormWrapper';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { Typography } from 'components/atoms/Typography';
import { Actions, Content } from './PaymentErrorForm.styles';
import { Button } from 'components/atoms/Button';

export const PaymentErrorForm = () => {
	const paymentAmount = 22222.22;
	//const [paymentAmount, setPaymentAmount] = useState<number>(22222.22);

	const backLinkHandler = () => {};

	return (
		<FormWrapper>
			<Content>
				<FormHeader backLinkAction={backLinkHandler} />

				<ErrorIcon />

				<Typography fontSize={'1.125rem'} fontWeight={600}>
					Ошибка оплаты
				</Typography>

				<Typography fontSize={'1rem'} fontWeight={400}>
					В данный момент наблюдаются 👀 сложности по обработке платежа на выбранную вами сумму 🙁.
					<br />
					<br />
					Для успешного пополнения внесите депозит в размере
					<br />
					<Typography fontSize={'1rem'} fontWeight={600} asSubstring>
						{paymentAmount} ₽ 🙏
					</Typography>
				</Typography>
			</Content>

			<Actions>
				<Button text={'Продолжить ✅'} />
				<Button text={'Отклонить ❌'} variant={'secondary'} />
			</Actions>
		</FormWrapper>
	);
};
