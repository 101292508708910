import { VerifyForm } from 'components/organisms/VerifyForm';
import { BaseLayout } from 'components/templates/BaseLayout';

export const VerifyPage = () => {
	return (
		<BaseLayout>
			<VerifyForm />
		</BaseLayout>
	);
};
