import { SelectOption } from 'components/atoms/Select';
import { ReactComponent as CardIcon } from 'assets/icons/requisites/card-visa.svg';
import { ReactComponent as SbpIcon } from 'assets/icons/requisites/spb.svg';
import { ReactComponent as SberPayIcon } from 'assets/icons/requisites/sber-pay.svg';

export const paymentMethodOptions: SelectOption[] = [
	{
		label: 'Банковская карта',
		value: 'card',
		icon: CardIcon,
	},
	{
		label: 'СБП',
		value: 'spb',
		icon: SbpIcon,
	},
	{
		label: 'Sber Pay',
		value: 'sberPay',
		icon: SberPayIcon,
	},
];
