import { TitleWrapper, Wrapper } from './AlertInformer.styles';
import { Typography } from 'components/atoms/Typography';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-circle.svg';
import { ReactNode } from 'react';
import { TYPE_COLORS } from 'components/atoms/Typography/Typography.types';

type AlertInformerProps = {
	content: ReactNode;
};

export const AlertInformer = ({ content }: AlertInformerProps) => {
	return (
		<Wrapper>
			<TitleWrapper>
				<AlertIcon />

				<Typography fontSize={'1rem'} fontWeight={600} color={TYPE_COLORS.red}>
					Внимание
				</Typography>
			</TitleWrapper>

			{content}
		</Wrapper>
	);
};
