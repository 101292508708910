import { ChangeEvent } from 'react';
import {
	ErrorContainer,
	InputContainer,
	InputLabel,
	InputStyled,
	InputStyledWrapper,
	InputWrapper,
	StartIconWrapper,
} from './Input.styles';
import { IInputProps } from '../types';

export const Input = ({
	value,
	label,
	placeholder,
	type = 'text',
	disabled = false,
	startIconProps,
	onChange,
	onBlur,
	className,
	error,
	inputStyle,
	inputInnerAfterComponent: InputInnerAfterComponent,
	inputInnerBeforeComponent: InputInnerBeforeComponent,
}: IInputProps) => {
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value: inputValue },
		} = event;
		if (onChange && !disabled) {
			onChange(inputValue, event);
		}
	};

	return (
		<InputWrapper className={className}>
			{label && <InputLabel>{label}</InputLabel>}
			<InputContainer>
				{startIconProps && (
					<StartIconWrapper $active={Boolean(startIconProps.onIconClick)} onClick={startIconProps.onIconClick}>
						{startIconProps.icon}
					</StartIconWrapper>
				)}
				<InputStyledWrapper>
					{InputInnerBeforeComponent}

					<InputStyled
						value={value}
						type={type}
						placeholder={placeholder}
						disabled={disabled}
						onChange={handleInputChange}
						onBlur={onBlur}
						style={inputStyle}
						$error={!!error}
					/>

					{InputInnerAfterComponent}
				</InputStyledWrapper>
			</InputContainer>
			{error && <ErrorContainer>{error}</ErrorContainer>}
		</InputWrapper>
	);
};
