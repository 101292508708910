import QRCode from 'react-qr-code';
import { Stack } from 'components/atoms/Stack';
import { ReactComponent as SberPayIcon } from 'assets/icons/requisites/sber-pay-2.svg';
import { Typography } from 'components/atoms/Typography';
import { QrWrapper, Wrapper } from './SberPayQr.styles';

type SberPayQrProps = {
	value: string;
	paymentAmount: number;
};

export const SberPayQr = ({ paymentAmount, value }: SberPayQrProps) => {
	return (
		<Wrapper>
			<Stack flexDirection={'column'}>
				<Stack alignItems={'center'}>
					<SberPayIcon />

					<Typography fontSize={'1rem'} fontWeight={600}>
						Sber pay
					</Typography>
				</Stack>

				<Typography textAlign={'start'}>
					Отсканируйте QR-код с камеры вашего устройства и переведите точную сумму
					<Typography fontWeight={600} asSubstring>
						{' '}
						{paymentAmount} ₽{' '}
					</Typography>
					одним платежом
				</Typography>
			</Stack>

			<QrWrapper>
				<QRCode size={160} value={value} />
			</QrWrapper>
		</Wrapper>
	);
};
