import styled from 'styled-components';
import { CSSObjectWithLabel } from 'react-select';
import { ContainerStatusType } from './types';

export const AutocompleteWrapper = styled.div`
	text-align: start;
`;

export const AutocompleteTitle = styled.div`
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 0.75rem;
	color: var(--text-dark);
`;

export const OptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
`;

const autocompleteDefaultSizes = {
	width: '100%',
};

export const containerStyles = (baseStyles: CSSObjectWithLabel) => ({
	...baseStyles,
	autocompleteDefaultSizes,
});

export const controlStyles = (baseStyles: CSSObjectWithLabel, status: ContainerStatusType) => {
	const borderColors = status === 'error' ? '#b42318' : '#d0d5dd';

	return {
		...baseStyles,
		...autocompleteDefaultSizes,
		padding: '0rem 0.75rem 0rem 0.75rem',
		border: `1px solid ${borderColors}`,
		borderRadius: '0.5rem',
		cursor: 'pointer',

		':disabled': {
			color: '#d0d5dd',
			backgroundColor: '#d0d5dd',
		},
		':hover': {
			border: `1px solid ${borderColors}`,
		},
	};
};

export const valueContainerStyles = (baseStyles: CSSObjectWithLabel) => ({
	...baseStyles,
	padding: '0rem',
	fontSize: '1rem',
	fontWeight: 500,
});

export const dropdownIndicatorStyles = (baseStyles: CSSObjectWithLabel) => ({
	...baseStyles,
	width: '1.4rem',
	height: '1.4rem',
	padding: '0rem',
});

export const optionStyles = (baseStyles: CSSObjectWithLabel) => ({
	...baseStyles,
	color: '#101828',
	cursor: 'pointer',
});

export const OptionStyled = styled.div`
	display: flex;
	align-items: center;
	height: 1.5rem;

	& > svg {
		width: 1.6rem;
		height: 1.6rem;
		margin-right: 1rem;
	}
`;
