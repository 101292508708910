import styled from 'styled-components';

export const Form = styled.div<{
	$defaultWidth: string;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: ${({ $defaultWidth }) => $defaultWidth};
	height: fit-content;
	gap: 2.5rem;
	background: var(--white);
	padding: 1.25rem;
	border-radius: 0.5rem;

	& > * {
		width: 100%;
	}
`;
