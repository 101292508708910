import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	gap: 0.5rem;
`;

export const ProgressBar = styled.div`
	display: block;
	position: relative;
	width: 100%;
	height: 0.5rem;
	border-radius: 1.25rem;
	overflow: hidden;
	background: var(--gray-primary);
`;

export const ProgressLine = styled.div<{ $percent: number }>`
	display: block;
	width: ${({ $percent }) => `${$percent}%`};
	height: 100%;
	border-radius: 1.25rem;
	background: var(--accent-purple);
	transition: 0.3s all;
`;
