import { CSSProperties, PropsWithChildren } from 'react';
import { StackContainer } from './Stack.styles';

type StackProps = PropsWithChildren & Pick<CSSProperties, 'flexDirection' | 'gap' | 'width' | 'justifyContent' | 'alignItems'>;

export const Stack = ({ children, ...props }: StackProps) => {
	const { flexDirection = 'row', gap = '0.5rem', width = 'fit-content', justifyContent = 'initial', alignItems = 'initial' } = props;

	return <StackContainer {...{ flexDirection, gap, width, justifyContent, alignItems }}>{children}</StackContainer>;
};
