import styled, { css } from 'styled-components';
import { ButtonSizeType, ButtonVariantType, ButtonWidthType } from './types';

const buttonsSizesMap = {
	l: {
		borderRadius: '1rem',
		padding: '1rem 1.8rem',
		fontSize: '1.6rem',
	},
	m: {
		borderRadius: '0.8rem',
		padding: '1rem',
		fontSize: '1.125rem',
	},
	s: {
		borderRadius: '0.8rem',
		padding: '0.8rem 1.4rem',
		fontSize: '1.4rem',
	},
};

const buttonsColorMap = {
	primary: {
		bgColor: 'var(--accent-purple)',
		color: '#FFFFFF',
		bgHover: 'var(--accent-purple)',
		colorHover: '#FFFFFF',
		border: 'none',
	},
	secondary: {
		bgColor: 'var(--accent-light-purple)',
		color: 'var(--accent-purple)',
		bgHover: 'var(--accent-light-purple)',
		colorHover: 'var(--accent-purple)',
		border: 'none',
	},
};

export const ButtonStyled = styled.button<{
	$variant: ButtonVariantType;
	$size: ButtonSizeType;
	$width: ButtonWidthType;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	outline: none;
	border: none;
	font-weight: 600;
	cursor: pointer;

	&:disabled {
		opacity: 0.7;
		cursor: default;
	}

	${({ $size }) => {
		return css`
			padding: ${buttonsSizesMap[$size].padding};
			font-size: ${buttonsSizesMap[$size].fontSize};
			border-radius: ${buttonsSizesMap[$size].borderRadius};
		`;
	}}

	${({ $width }) =>
		$width === 'fullwidth' &&
		css`
			width: 100%;
		`};

	${({ $variant }) => {
		return css`
			background-color: ${buttonsColorMap[$variant].bgColor};
			border: 1px solid ${buttonsColorMap[$variant].border};
			color: ${buttonsColorMap[$variant].color};

			& > svg {
				stroke: ${buttonsColorMap[$variant].color};
			}

			&:hover:enabled {
				background-color: ${buttonsColorMap[$variant].bgHover};
				color: ${buttonsColorMap[$variant].colorHover};
				opacity: 0.8;
			}

			&:active:enabled {
				background-color: ${buttonsColorMap[$variant].bgHover};
				color: ${buttonsColorMap[$variant].colorHover};
			}
		`;
	}}
`;

export const StartIconWrapper = styled.div`
	margin-right: 0.8rem;
	display: flex;
	align-items: center;
`;

export const EndIconWrapper = styled.div`
	margin-left: 0.8rem;
	display: flex;
	align-items: center;
`;
