import { PaymentConfirmationForm } from 'components/organisms/PaymentConfirmationForm';
import { BaseLayout } from 'components/templates/BaseLayout';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PaymentTypes } from 'shared/constants/paymentTypes';

export const PaymentConfirmationPage = () => {
	const navigate = useNavigate();
	const { paymentType } = useParams();

	useEffect(() => {
		if (!paymentType) navigate('/');

		if (!PaymentTypes?.[paymentType as PaymentTypes]) navigate('/');
	}, [navigate, paymentType]);

	return (
		<BaseLayout>
			<PaymentConfirmationForm paymentType={paymentType as PaymentTypes} />
		</BaseLayout>
	);
};
