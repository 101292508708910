import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Header, ModalStyled, ModalWrapper } from './Modal.styles';
import { ModalProps } from '../Modal.types';

export const BaseModal = ({ children, isOpen, setIsOpen, maxWidth = '30rem' }: ModalProps) => {
	const modalRef = useRef<HTMLDivElement>(null);

	const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

	const handleClickOutside = useCallback(
		(event: Event) => {
			const path = event.composedPath();

			if (modalRef.current && !path.includes(modalRef.current)) {
				handleClose();
			}
		},
		[handleClose]
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [handleClickOutside]);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [isOpen]);

	return (
		<ModalWrapper isOpen={isOpen}>
			<ModalStyled ref={modalRef} maxWidth={maxWidth}>
				<Header>
					<CloseIcon onClick={handleClose} cursor={'pointer'} />
				</Header>
				{children}
			</ModalStyled>
		</ModalWrapper>
	);
};

export const Modal = (props: ModalProps) => {
	const portal = document.getElementById('modalPortal');
	if (!portal) return <></>;

	return createPortal(<BaseModal {...props} />, portal);
};
