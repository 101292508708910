import styled from 'styled-components';
import { breakpoints } from 'shared';

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	width: 100%;
	min-height: 100vh;
	overflow-y: auto;

	@media (max-width: ${breakpoints.mobile}) {
		justify-content: flex-start;
	}
`;
