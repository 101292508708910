import { useState } from 'react';
import { Select, SelectOption } from 'components/atoms/Select';
import { FormHeader } from 'components/molecules/FormHeader/ui/FormHeader';
import { FormWrapper } from 'components/molecules/FormWrapper/FormWrapper';
import { paymentMethodOptions } from '../PaymentMethodForm.constants';
import { Button } from 'components/atoms/Button';
import { InputNumber } from 'components/atoms/InputNumber';

export const PaymentMethodForm = () => {
	const [paymentAmount, setPaymentAmount] = useState('');
	const [paymentMethod, setPaymentMethod] = useState<SelectOption | null>(null);

	const backLinkHandler = () => {};

	return (
		<FormWrapper>
			<FormHeader title='Выберите метод пополнения' backLinkAction={backLinkHandler} />

			<Select
				value={paymentMethod}
				onChange={setPaymentMethod}
				options={paymentMethodOptions}
				isSearchable={false}
				label={'Платежная система'}
				placeholder={'Платёжная система'}
			/>

			<InputNumber value={paymentAmount} onChange={setPaymentAmount} label='Сумма пополнения(₽)' placeholder='1000.00' />

			<Button text={'Продолжить'} />
		</FormWrapper>
	);
};
