import { ConfirmNumberPage } from 'components/pages/ConfirmNumberPage';
import { VerifyPage } from 'components/pages/VerifyPage';
import { PaymentMethodPage } from 'components/pages/PaymentMethodPage';
import { PaymentErrorPage } from 'components/pages/PaymentErrorPage';
import { PaymentConfirmedPage } from 'components/pages/PaymentConfirmedPage';
import { PaymentExpiredPage } from 'components/pages/PaymentExpiredPage';
import { PaymentConfirmationPage } from 'components/pages/PaymentConfirmationPage';

interface IRouteItem {
	path: string;
	element: React.ComponentType<Record<string, unknown>>;
	isAvailable?: boolean;
}

export const routes: IRouteItem[] = [
	{
		path: '/verify',
		element: VerifyPage,
		isAvailable: true,
	},
	{
		path: '/confirmNumber',
		element: ConfirmNumberPage,
		isAvailable: true,
	},
	{
		path: '/paymentMethod',
		element: PaymentMethodPage,
		isAvailable: true,
	},
	{
		path: '/paymentConfirmation/:paymentType',
		element: PaymentConfirmationPage,
		isAvailable: true,
	},
	{
		path: '/paymentError',
		element: PaymentErrorPage,
		isAvailable: true,
	},
	{
		path: '/paymentConfirmed',
		element: PaymentConfirmedPage,
		isAvailable: true,
	},
	{
		path: '/paymentExpired',
		element: PaymentExpiredPage,
		isAvailable: true,
	},
];
