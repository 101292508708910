import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
`;

export const Actions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`;
