import styled from 'styled-components';

export const StackContainer = styled.div<{
	flexDirection: string;
	gap: string | number;
	width: string | number;
	justifyContent: string;
	alignItems: string;
}>`
	display: flex;
	flex-direction: ${({ flexDirection }) => flexDirection};
	gap: ${({ gap }) => gap};
	width: ${({ width }) => width};
	justify-content: ${({ justifyContent }) => justifyContent};
	align-items: ${({ alignItems }) => alignItems};
`;
