import { BaseLayout } from 'components/templates/BaseLayout';
import { PaymentExpiredForm } from 'components/organisms/PaymentExpiredForm';

export const PaymentExpiredPage = () => {
	return (
		<BaseLayout>
			<PaymentExpiredForm />
		</BaseLayout>
	);
};
