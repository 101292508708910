import styled from 'styled-components';

export const ModalWrapper = styled.div<{ isOpen: boolean }>`
	display: flex;
	position: fixed;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	overflow-y: auto;
	padding: 1rem;
	background-color: rgba(19, 22, 31, 0.6);
	transition: 0.15s all;
	z-index: 100;

	opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
	visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

export const ModalStyled = styled.div<{ maxWidth: string }>`
	position: relative;
	padding: 1.25rem;
	border-radius: 0.5rem;
	background: #ffffff;
	width: 100%;
	max-width: ${({ maxWidth }) => maxWidth};
`;

export const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;
