import { FormHeader } from 'components/molecules/FormHeader/ui/FormHeader';
import { FormWrapper } from 'components/molecules/FormWrapper/FormWrapper';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { HeaderWrapper } from './PaymentConfirmedForm.styles';
import { Typography } from 'components/atoms/Typography';
import { Button } from 'components/atoms/Button';
import { InfoField } from 'components/atoms/InfoField';

export const PaymentConfirmedForm = () => {
	const mockPaymentId = '4990ac66-2fca-4988-8361-949778d';

	const backLinkHandler = () => {};

	return (
		<FormWrapper>
			<HeaderWrapper>
				<FormHeader backLinkAction={backLinkHandler} />

				<SuccessIcon />

				<Typography fontSize={'1.125rem'} fontWeight={600}>
					Платёж принят
				</Typography>

				<Typography fontSize={'1rem'} fontWeight={400}>
					Оплата прошла успешно ✅.
					<br />
					Вы можете закрыть окно или вернуться в магазин 🛒.
				</Typography>
			</HeaderWrapper>

			<InfoField leftLabel={'Номер платежа'} rightLabel={'19.05.2024 13:26:37'} value={mockPaymentId} copyable />

			<Button text={'Вернуться в магазин 🛒'} />
		</FormWrapper>
	);
};
