import { FormWrapper } from 'components/molecules/FormWrapper/FormWrapper';
import { FormHeader } from 'components/molecules/FormHeader/ui/FormHeader';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { Actions, Content } from './PaymentExpiredForm.styles';
import { Button } from 'components/atoms/Button';
import { Typography } from 'components/atoms/Typography';
import { InfoField } from 'components/atoms/InfoField';

export const PaymentExpiredForm = () => {
	const mockPaymentId = '4990ac66-2fca-4988-8361-949778d';

	const backLinkHandler = () => {};

	return (
		<FormWrapper>
			<Content>
				<FormHeader backLinkAction={backLinkHandler} />

				<ErrorIcon />

				<Typography fontSize={'1.125rem'} fontWeight={600}>
					Истек срок оплаты
				</Typography>

				<Typography fontSize={'1rem'} fontWeight={400}>
					К сожалению, время на выполнение оплаты истекло 🙁.
					<br />
					Если вы все еще хотите совершить платёж, пожалуйста, вернитесь в магазин и оформите платёж заново 🙏
				</Typography>
			</Content>

			<InfoField leftLabel={'Номер платежа'} rightLabel={'19.05.2024 13:26:37'} value={mockPaymentId} copyable />

			<Actions>
				<Button text={'Оплатить заново ✅'} />
				<Button text={'Отменить ❌'} variant={'secondary'} />
			</Actions>
		</FormWrapper>
	);
};
