export const ruPhoneMask = (phone: string) => {
	const numericString = phone.replace(/\D/g, '');
	if (!phone?.length) return '';

	const lengths = [1, 3, 3, 2, 2];
	let formattedNumber = '+';

	let startIndex = 0;
	for (let i = 0; i < lengths.length; i++) {
		formattedNumber += numericString.slice(startIndex, startIndex + lengths[i]);
		startIndex += lengths[i];
		if (i < lengths.length - 1) {
			formattedNumber += ' ';
		}
	}

	return formattedNumber.trim();
};
