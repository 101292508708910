import styled from 'styled-components';

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const HeaderContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
