import { useCallback, useEffect, useState } from 'react';

type UseCountdownParams = {
	seconds: number;
	convertToCountdown: (value: number) => string;
};

export const useCountdown = ({ seconds, convertToCountdown }: UseCountdownParams) => {
	const [secondsLeft, setSecondsLeft] = useState<number>(seconds);
	const [formattedCountdown, setFormattedCountdown] = useState('');

	const isExpired = secondsLeft === 0;

	const resetTimer = useCallback(() => {
		setSecondsLeft(seconds);
	}, [seconds]);

	useEffect(() => {
		const countdown = convertToCountdown(seconds);
		setFormattedCountdown(countdown);
	}, [seconds, convertToCountdown]);

	useEffect(() => {
		const timerInterval = setInterval(() => {
			const countdown = convertToCountdown(secondsLeft);

			setFormattedCountdown(countdown);

			if (secondsLeft === 0) {
				clearInterval(timerInterval);
				return;
			}

			setSecondsLeft(secondsLeft - 1);
		}, 1000);

		return () => clearInterval(timerInterval);
	}, [secondsLeft, convertToCountdown, setFormattedCountdown]);

	return { secondsLeft, isExpired, formattedCountdown, resetTimer };
};
