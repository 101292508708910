import { CSSProperties } from 'react';

import { TYPE_COLORS, TypeProps } from '../Typography.types';
import { Type, TypeSpan } from './Typography.styles';
import { colorsMap, defaultStyles } from '../Typography.constants';

export const Typography = ({
	children,
	fontSize,
	fontWeight,
	color,
	textAlign,
	width,
	margin,
	padding,
	lineHeight,
	asSubstring,
}: TypeProps) => {
	const colorProp = colorsMap?.[color || TYPE_COLORS.black];

	const typeStyle: CSSProperties = {
		fontSize: fontSize || defaultStyles.fontSize,
		fontWeight: fontWeight || defaultStyles.fontWeight,
		width,
		margin,
		padding,
		textAlign,
		lineHeight,
	};

	if (asSubstring)
		return (
			<TypeSpan $color={colorProp} style={typeStyle}>
				{children}
			</TypeSpan>
		);

	return (
		<Type $color={colorProp} style={typeStyle}>
			{children}
		</Type>
	);
};
