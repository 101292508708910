import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.75rem;
`;

export const LabelsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 0.75rem;
`;

export const ChevronIconWrapper = styled.div`
	display: block;
	position: absolute;
	left: 0.75rem;
	width: 1.5rem;
	height: 1.5rem;
`;

export const CopyIconWrapper = styled.div`
	display: block;
	position: absolute;
	right: 0.75rem;
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`;
