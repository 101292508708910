import { useState } from 'react';
import { Typography } from 'components/atoms/Typography';
import { FormWrapper } from 'components/molecules/FormWrapper/FormWrapper';
import { Actions, FormContent } from './VerifyForm.styles';
import { Button } from 'components/atoms/Button';
import { ReactComponent as TelegramIcon } from 'assets/icons/socials/telegram.svg';
import { InputMask } from 'components/atoms/InputMask';
import { ruPhoneMask } from 'utils/requisites/phone/phone.mask';
import { usePhoneValidation } from 'hooks/requisites/usePhoneValidation';

export const VerifyForm = () => {
	const [number, setNumber] = useState('');
	const { errorPhone, triggerPhoneValidation } = usePhoneValidation({ phoneNumber: number });

	const submitHandler = () => {
		triggerPhoneValidation();
	};

	return (
		<FormWrapper>
			<FormContent>
				<Typography width={'100%'} textAlign={'start'} fontWeight={600} fontSize={'1.125rem'}>
					Верификация
				</Typography>
				<Typography width={'100%'} textAlign={'start'} fontWeight={400} fontSize={'1rem'}>
					Чтобы продолжить, вам необходимо пройти верификацию. Пожалуйста, введите ваш номер телефона в поле ниже, или перейдите в нашего
					Telegram бота для подтверждения номера.
				</Typography>
			</FormContent>

			<InputMask
				value={number}
				onChange={setNumber}
				maskFormatter={ruPhoneMask}
				label='Введите ваш номер телефона'
				placeholder='Номер телефона'
				error={errorPhone}
				onBlur={triggerPhoneValidation}
			/>

			<Actions>
				<Button onClick={submitHandler} text={'Продолжить'} />
				<Button variant={'secondary'} text={'Подтвердить через бота'} endIcon={<TelegramIcon width={'1.5rem'} height={'1.5rem'} />} />
			</Actions>
		</FormWrapper>
	);
};
