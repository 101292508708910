import React, { SVGProps } from 'react';
import { ReactComponent as TinkoffIcon } from 'assets/icons/requisites/tinkoff.svg';

export enum Banks {
	'tinkoff' = 'tinkoff',
}

export const bankNames: Record<Banks, string> = {
	[Banks.tinkoff]: 'Тинькоф',
};

export const bankIcons: Record<Banks, React.FC<SVGProps<SVGSVGElement>>> = {
	[Banks.tinkoff]: TinkoffIcon,
};
