import { useEffect, useState } from 'react';

type UseValidationParams = {
	value: string;
	errorText: string;
	validator: (value: string) => boolean;
};

export const useValidation = ({ value, errorText, validator }: UseValidationParams) => {
	const [isValid, setIsValid] = useState(true);

	const error = !isValid ? errorText : '';

	const triggerValidation = () => {
		const isValid = validator(value);
		setIsValid(isValid);
		return isValid;
	};

	const resetValidation = () => {
		setIsValid(true);
	};

	useEffect(() => {
		setIsValid(true);
	}, [value]);

	return { resetValidation, triggerValidation, isValid, error };
};
