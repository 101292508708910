import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.25rem 1rem;
	gap: 0.5rem;
	background: var(--warning-red);
	border-radius: 0.5rem;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
`;
