import {
  ButtonStyled,
  EndIconWrapper,
  StartIconWrapper,
} from './Button.styles';
import { IButtonProps } from './types';

export const Button = ({
  text,
  onClick,
  size = 'm',
  variant = 'primary',
  width = 'shortened',
  isDisabled,
  startIcon,
  endIcon,
  className,
}: IButtonProps) => {
  return (
    <ButtonStyled
      className={className}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled}
      $variant={variant}
      $size={size}
      $width={width}
    >
      {startIcon ? <StartIconWrapper>{startIcon}</StartIconWrapper> : null}
      {text}
      {endIcon ? <EndIconWrapper>{endIcon}</EndIconWrapper> : null}
    </ButtonStyled>
  );
};
