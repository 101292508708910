import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from './config/routes';
import './App.css';

function App() {
	const availableRoutes = routes.filter((route) => route.isAvailable);

	return (
		<div className='App'>
			<Routes>
				{availableRoutes.map(({ path, element }) => {
					const Component = element;
					return <Route key={path} path={path} element={<Component />} />;
				})}
				<Route path='*' element={<Navigate to='/verify' />} />
			</Routes>
		</div>
	);
}

export default App;
