import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: var(--gray-secondary);
	padding: 1.25rem 1rem;
	border-radius: 0.5rem;
	align-items: center;
	gap: 1.5rem;
`;

export const QrWrapper = styled.div`
	display: block;
	padding: 1rem;
	border-radius: 1rem;
	background: var(--white);
`;
