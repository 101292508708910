import styled from 'styled-components';

export const Type = styled.p<{ $color: string }>`
	font-size: 1rem;
	font-weight: 400;
	font-family: 'Inter';
	color: ${({ $color }) => $color};
`;

export const TypeSpan = styled.span<{ $color: string }>`
	font-size: 1rem;
	font-weight: 400;
	font-family: 'Inter';
	color: ${({ $color }) => $color};
`;
