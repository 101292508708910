import { FormWrapper } from 'components/molecules/FormWrapper/FormWrapper';
import { PaymentConfirmationFormProps } from '../PaymentConfirmationForm.types';
import { FormHeader } from 'components/molecules/FormHeader/ui/FormHeader';
import { paymentConfirmationFormTitles, paymentFieldsMap } from '../PaymentConfirmationForm.constants';
import { Stack } from 'components/atoms/Stack';
import { Button } from 'components/atoms/Button';
import { CountdownBar } from 'components/molecules/CountdownBar';
import { PaymentAlertInformer } from './PaymentConfirmationForm.parts';
import { PaymentCancelModal } from './parts/PaymentCancelModal/PaymentCancelModal';
import { useState } from 'react';

const countdownTimeoutMinutes = 20;
const countdownTimeoutSeconds = countdownTimeoutMinutes * 60;

export const PaymentConfirmationForm = ({ paymentType }: PaymentConfirmationFormProps) => {
	const [isPaymentCancelModalOpen, setIsPaymentCancelModalOpen] = useState(false);

	const backLinkHandler = () => {};

	const formHeaderTitle = paymentConfirmationFormTitles?.[paymentType] || '';
	const Fields = paymentFieldsMap?.[paymentType];

	const cancelPaymentHandler = () => {
		setIsPaymentCancelModalOpen(true);
	};

	return (
		<>
			<FormWrapper>
				<FormHeader title={formHeaderTitle} backLinkAction={backLinkHandler} />

				<CountdownBar label={'Время на оплату'} timeoutSeconds={countdownTimeoutSeconds} />

				<Stack flexDirection={'column'} gap={'1.25rem'} width={'100%'}>
					{Fields && <Fields />}

					<PaymentAlertInformer />
				</Stack>

				<Stack flexDirection={'column'} gap={'0.5rem'} width={'100%'}>
					<Button text={'Я оплатил ✅'} />
					<Button onClick={cancelPaymentHandler} text={'Отменить платёж ❌'} variant={'secondary'} />
				</Stack>
			</FormWrapper>
			<PaymentCancelModal isOpen={isPaymentCancelModalOpen} setIsOpen={setIsPaymentCancelModalOpen} />
		</>
	);
};
