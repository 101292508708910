import { InfoField } from 'components/atoms/InfoField';
import { Stack } from 'components/atoms/Stack';
import { Banks, bankIcons, bankNames } from 'shared/constants/banks';

export const SbpFields = () => {
	const mockBank = Banks.tinkoff;
	const mockBuyerName = 'Алексей Михайлович Г.';
	const mockBuyerPhone = '+79453244534';
	const mockPaymentAmount = '2222';
	const mockPaymentId = '4990ac66-2fca-4988-8361-949778d';

	const bankName = bankNames?.[mockBank];
	const BankIcon = bankIcons?.[mockBank];

	return (
		<Stack flexDirection={'column'} gap={'1.25rem'} width={'100%'}>
			<Stack flexDirection={'row'} gap={'1.25rem'} width={'100%'}>
				<InfoField leftLabel='Банк получателя' chevron={BankIcon} value={bankName} />
				<InfoField leftLabel='Имя получателя' value={mockBuyerName} />
			</Stack>
			<Stack flexDirection={'row'} gap={'1.25rem'} width={'100%'}>
				<InfoField leftLabel='Номер получателя' value={mockBuyerPhone} copyable />
				<InfoField leftLabel='Сумма платежа' value={`${mockPaymentAmount} ₽`} copyable />
			</Stack>

			<InfoField leftLabel='Номер платежа' value={mockPaymentId} copyable />
		</Stack>
	);
};
