import { PaymentConfirmedForm } from 'components/organisms/PaymentConfirmedForm/ui/PaymentConfirmedForm';
import { BaseLayout } from 'components/templates/BaseLayout';

export const PaymentConfirmedPage = () => {
	return (
		<BaseLayout>
			<PaymentConfirmedForm />
		</BaseLayout>
	);
};
