import { Input } from 'components/atoms/Input';
import { Typography } from 'components/atoms/Typography';
import { InfoFieldProps } from '../InfoField.types';
import { Copy } from './InfoField.parts';

import { ChevronIconWrapper, LabelsWrapper, Wrapper } from './InfoField.styles';

const ChevronIcon = ({ chevron: Chevron }: Pick<InfoFieldProps, 'chevron'>) => {
	if (!Chevron) return <></>;

	return (
		<ChevronIconWrapper>
			<Chevron width={'100%'} height={'100%'} />
		</ChevronIconWrapper>
	);
};

export const InfoField = ({ value, leftLabel, rightLabel, copyable, chevron }: InfoFieldProps) => {
	const inputLeftPadding = chevron ? '2.5rem' : '0.625rem';
	const inputRightPadding = copyable ? '2.5rem' : '0.625rem';

	return (
		<Wrapper>
			<LabelsWrapper>
				{leftLabel && (
					<Typography fontSize={'1rem'} fontWeight={400}>
						{leftLabel}
					</Typography>
				)}

				{rightLabel && (
					<Typography fontSize={'1rem'} fontWeight={400}>
						{rightLabel}
					</Typography>
				)}
			</LabelsWrapper>

			<Input
				value={value}
				inputInnerBeforeComponent={chevron && <ChevronIcon chevron={chevron} />}
				inputInnerAfterComponent={copyable && <Copy value={value} />}
				placeholder=''
				disabled
				inputStyle={{
					padding: `0.625rem ${inputRightPadding} 0.625rem ${inputLeftPadding}`,
				}}
			/>
		</Wrapper>
	);
};
