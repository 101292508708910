import { useValidation } from 'hooks/useValdiation';
import { validatePhone } from 'utils/requisites/phone/phone.validation';

const invalidNumberError = 'Некорректный номер телефона';

type UsePhoneValidationParams = {
	phoneNumber: string;
};

export const usePhoneValidation = ({ phoneNumber }: UsePhoneValidationParams) => {
	const {
		isValid: isValidNumber,
		error: errorPhone,
		triggerValidation: triggerPhoneValidation,
	} = useValidation({
		value: phoneNumber,
		errorText: invalidNumberError,
		validator: validatePhone,
	});

	return { triggerPhoneValidation, isValidNumber, errorPhone };
};
