import { PaymentMethodForm } from 'components/organisms/PaymentMethodForm';
import { BaseLayout } from 'components/templates/BaseLayout';

export const PaymentMethodPage = () => {
	return (
		<BaseLayout>
			<PaymentMethodForm />
		</BaseLayout>
	);
};
