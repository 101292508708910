import { Modal } from 'components/atoms/Modal';
import { ModalProps } from 'components/atoms/Modal/Modal.types';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { Stack } from 'components/atoms/Stack';
import { Button } from 'components/atoms/Button';
import { Typography } from 'components/atoms/Typography';

export const PaymentCancelModal = ({ isOpen, setIsOpen }: Pick<ModalProps, 'isOpen' | 'setIsOpen'>) => {
	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<Stack flexDirection={'column'} width={'100%'} gap={'1.875rem'}>
				<Stack flexDirection={'column'} width={'100%'} alignItems={'center'} gap={'0.5rem'}>
					<WarningIcon />

					<Typography fontSize={'1.125rem'} fontWeight={600}>
						Отмена платежа
					</Typography>

					<Typography fontSize={'1rem'} fontWeight={400} textAlign={'center'}>
						Вы уверены, что хотите отменить платёж?
					</Typography>
				</Stack>

				<Stack flexDirection={'column'} width={'100%'}>
					<Button text={'Отменить'} />
					<Button text={'Не отменять'} variant={'secondary'} />
				</Stack>
			</Stack>
		</Modal>
	);
};
