import { TYPE_COLORS } from './Typography.types';

export const defaultStyles = {
	fontSize: '1rem',
	fontWeight: 400,
};

export const colorsMap: Record<TYPE_COLORS, string> = {
	[TYPE_COLORS.black]: 'var(--text-dark)',
	[TYPE_COLORS.purple]: 'var(--accent-purple)',
	[TYPE_COLORS.red]: 'var(--text-red)',
};
