import ReactSelect, { GroupBase, OptionProps, SingleValueProps, components } from 'react-select';
import {
	AutocompleteTitle,
	AutocompleteWrapper,
	OptionContainer,
	OptionStyled,
	containerStyles,
	controlStyles,
	dropdownIndicatorStyles,
	optionStyles,
	valueContainerStyles,
} from './Select.styles';
import { SelectOption, SelectProps } from './types';

const Option = (props: OptionProps<SelectOption, false>) => {
	const Icon = props?.data?.icon;

	return (
		<components.Option {...props}>
			<OptionStyled>
				{Icon && <Icon />}
				{props.label}
			</OptionStyled>
		</components.Option>
	);
};

const SelectedOption = ({ ...props }: SingleValueProps<SelectOption, false, GroupBase<SelectOption>>) => {
	const { data } = props;
	const Icon = data?.icon;

	return (
		<components.SingleValue {...props}>
			<OptionContainer>
				{Icon && <Icon />}
				{data?.label}
			</OptionContainer>
		</components.SingleValue>
	);
};

export const Select = ({
	value,
	label,
	options,
	placeholder,
	status,
	isSearchable = false,
	isDisabled = false,
	isClearable = false,
	onChange,
}: SelectProps<SelectOption>) => {
	const handleOnChange = (newValue: SelectOption | null) => {
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<AutocompleteWrapper>
			{label && <AutocompleteTitle>{label}:</AutocompleteTitle>}
			<ReactSelect
				value={value}
				options={options}
				placeholder={placeholder}
				hideSelectedOptions={false}
				isDisabled={isDisabled}
				isSearchable={isSearchable}
				isClearable={isClearable}
				styles={{
					container: containerStyles,
					control: (baseStyles) => controlStyles(baseStyles, status),
					valueContainer: valueContainerStyles,
					dropdownIndicator: dropdownIndicatorStyles,
					option: optionStyles,
				}}
				components={{
					IndicatorSeparator: null,
					Option,
					SingleValue: SelectedOption,
				}}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: '#d1d4da',
						primary25: '#e0e2e6',
					},
				})}
				onChange={!isDisabled ? handleOnChange : undefined}
			/>
		</AutocompleteWrapper>
	);
};
