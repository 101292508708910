import { ConfirmNumberForm } from 'components/organisms/ConfirmNumberForm';
import { BaseLayout } from 'components/templates/BaseLayout';

export const ConfirmNumberPage = () => {
	return (
		<BaseLayout>
			<ConfirmNumberForm />
		</BaseLayout>
	);
};
