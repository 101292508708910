import { PaymentTypes } from 'shared/constants/paymentTypes';
import { SbpFields } from './ui/parts/SbpFields/SbpFields';
import { CardFields } from './ui/parts/CardFields/CardFields';
import { SberPayFields } from './ui/parts/SberPayFields/SberPayFields';

export const paymentConfirmationFormTitles: Record<PaymentTypes, string> = {
	[PaymentTypes.sbp]: 'Перевод по СБП',
	[PaymentTypes.card]: 'Перевод на банковскую карту',
	[PaymentTypes.sberPay]: 'Перевод по Sber Pay',
};

export const paymentFieldsMap: Record<PaymentTypes, () => JSX.Element> = {
	[PaymentTypes.sbp]: SbpFields,
	[PaymentTypes.card]: CardFields,
	[PaymentTypes.sberPay]: SberPayFields,
};
