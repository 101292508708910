import styled from 'styled-components';

export const InputContainer = styled.div`
	position: relative;
`;

export const InputLabel = styled.p`
	font-size: 1rem;
	font-weight: 400;
	text-align: start;
	color: var(--text-dark);
	margin-bottom: 0.75rem;
`;

export const InputStyledWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	width: 100%;
`;

export const InputStyled = styled.input<{ $error?: boolean }>`
	width: 100%;
	font-size: 1rem;
	font-weight: 500;
	padding: 0.625rem 0.875rem;
	border-radius: 0.5rem;
	border: 1px solid #d0d5dd;
	color: #101828;
	${({ $error }) => $error && 'border: 1px solid var(--failure-red);'}

	&::placeholder {
		color: #667085;
		font-weight: 400;
	}

	&::-ms-input-placeholder {
		color: #667085;
		font-size: 1.6rem;
		font-weight: 400;
	}

	&:focus {
		border-color: transparent;
		outline: 2px solid var(--accent-purple);
	}

	&:disabled {
		background-color: #f9fafb;
		border: 1px solid #d0d5dd;
		color: #667085;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}
`;

export const StartIconWrapper = styled.div<{ $active?: boolean }>`
	position: absolute;
	width: 1.6rem;
	height: 1.6rem;
	top: 2rem;
	right: 2.4rem;

	${({ $active }) => $active && 'cursor: pointer;'}

	& svg {
		width: 1.6rem;
		height: 1.6rem;

		& > path {
			stroke: #667085;
		}
	}
`;

export const InputWrapper = styled.div`
	position: relative;
`;

export const ErrorContainer = styled.p`
	text-align: start;
	font-size: 1rem;
	font-weight: 500;
	margin-top: 0.25rem;
	color: var(--failure-red);
`;
