import { IInputProps } from 'components/atoms/Input';
import { copyToClipboard } from 'utils/copyToClipboard';
import { CopyIconWrapper } from './InfoField.styles';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

export const Copy = ({ value }: Pick<IInputProps, 'value'>) => {
	const copyHandler = () => copyToClipboard({ value });

	return (
		<CopyIconWrapper onClick={copyHandler}>
			<CopyIcon />
		</CopyIconWrapper>
	);
};
