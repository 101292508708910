import { ReactComponent as BackLink } from 'assets/icons/back-arrow.svg';
import { FormHeaderProps } from '../FormHeader.types';
import { Typography } from 'components/atoms/Typography';
import { Wrapper, backLinkStyles } from './FormHeader.styles';

export const FormHeader = ({ title, backLinkAction }: FormHeaderProps) => {
	return (
		<Wrapper>
			{backLinkAction && <BackLink style={backLinkStyles} onClick={backLinkAction} />}
			{title && (
				<Typography fontSize={'1.125rem'} fontWeight={600}>
					{title}
				</Typography>
			)}
		</Wrapper>
	);
};
